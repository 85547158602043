import React from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Paper,
  Grid,
  Box,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@emotion/react';
import * as Yup from 'yup';
import AppbarWithNavigation from '../../../components/core/appBar';
import CustomAlert from '../../../components/common/CustomAlert';
import { getCurrentUser } from '../../../services/auth.services';
import { contactUs } from '../../../services/users.services';
import config from '../../../config';

const schema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required(),
  subject: Yup.string().required().max(50).label('Subject'),
  message: Yup.string().required().max(500).label('Message'),
});

export default function ContactUs() {
  const user = getCurrentUser();
  const theme = useTheme();
  const [data, setData] = React.useState({
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
    subject: '',
    message: '',
    theme: theme.palette.mode,
  });
  const [alert, setAlert] = React.useState({ severity: '', message: '' });
  const [loading, setLoading] = React.useState(false);

  const handleSuccess = (message) => {
    setAlert({ severity: 'success', message });
  };
  const handleError = (message) => {
    setAlert({ severity: 'error', message });
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    schema
      .validate(data)
      .then(() => {
        contactUs(data)
          .then(() => {
            handleSuccess('Message sent successfully');
            setData({ ...data, subject: '', message: '' });
            setLoading(false);
            setData({ ...data, subject: '', message: '' });
          })
          .catch((ex) => {
            setLoading(false);
            handleError(ex?.response?.data?.message || config.errors.default);
          });
      })
      .catch((err) => {
        setLoading(false);
        handleError(err?.errors[0] || config.errors.default);
      });
  };
  return (
    <>
      <AppbarWithNavigation />
      <Container maxWidth="md">
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '90vh' }}
        >
          <Grid item xs={4}>
            <Paper>
              <Box p={2}>
                <Box mb={3}>
                  <Typography variant="h4" gutterBottom color="primary">
                    Contact Us
                  </Typography>
                  <Typography variant="body1" paragraph color="textSecondary">
                    Please use the form below to get in touch with us. We will
                    respond to your message as soon as possible.
                  </Typography>
                </Box>
                <Box>
                  <CustomAlert
                    severity={alert?.severity}
                    message={alert?.message}
                    disapearMsg
                    onClose={() => setAlert({})}
                  />

                  <form onSubmit={handleSubmit}>
                    <TextField
                      label="Name *"
                      name="name"
                      variant="outlined"
                      value={data.name}
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      disabled
                      size="small"
                    />
                    <TextField
                      label="Email *"
                      type="email"
                      variant="outlined"
                      value={data.email}
                      disabled
                      onChange={handleChange}
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                    <TextField
                      label="Subject *"
                      type="text"
                      name="subject"
                      value={data.subject}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      size="small"
                    />
                    <TextField
                      label="Message *"
                      multiline
                      rows={6}
                      name="message"
                      value={data.message}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2, mb: 1 }}
                      endIcon={
                        loading && (
                          <CircularProgress color="inherit" size={18} />
                        )
                      }
                      disabled={loading}
                    >
                      {' '}
                      Submit
                    </Button>
                  </form>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
