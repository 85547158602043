import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import conf from '../../../config';
// import { SET_APP } from "../../redux/settings/settings.actions";

import actionTypes from '../../../redux/actions';
// import { isAppUser, isCustomerAdmin } from "../../../services/auth.services";

const QCA = conf.appSettings.app.qca;
const CUSTOMER_ADMIN = conf.appSettings.app.customerAdmin;
// const QUARRIOADMIN = conf.appSettings.app.quarrioAdmin;

export default function AppSwitcher() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentApp = useSelector((s) => s.settings.app);

  const handleChange = (event) => {
    const val = event.target.value;

    dispatch({ type: actionTypes.settings.SET_APP, payload: val });
    // setTimeout(() => {
    if (val === QCA) {
      navigate(conf.routes.qca.askQuestion, { replace: true });
    }
    if (val === CUSTOMER_ADMIN) {
      navigate(conf.routes.customerAdmin.users, { replace: true });
    }
    // }, 1000);
  };
  return (
    <div>
      <FormControl sx={{ p: 0, minWidth: 100, borderWidth: 0 }} size="small">
        <Select
          sx={{
            minWidth: 100,
            '& .MuiSvgIcon-root': {
              color: 'white',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '0px solid white',
            },

            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                border: '0px solid white',
              },
              // '&:hover fieldset': {
              //   borderColor: 'yellow',
              // },
              // '&.Mui-focused fieldset': {
              //   borderColor: 'green',
              // },
            },
            border: '0px solid white',
            color: (t) => t.palette.common.white,

            // borderColor: (t) => t.palette.common.white,
          }}
          id="select-autowidth"
          value={currentApp}
          onChange={handleChange}
          // autoWidth
          size="small"
          variant="outlined"
        >
          <MenuItem value={QCA} size="small">
            APP
          </MenuItem>

          <MenuItem value={CUSTOMER_ADMIN} size="small">
            Admin
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
