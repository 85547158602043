import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import HistoryIcon from "@mui/icons-material/History";

// import MemoryIcon from "@mui/icons-material/Memory";

import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import QuestionAnswerTwoToneIcon from '@mui/icons-material/QuestionAnswerTwoTone';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';

// import GroupIcon from "@mui/icons-material/Group";
import MessageTwoToneIcon from '@mui/icons-material/MessageTwoTone';

// import DatasetIcon from "@mui/icons-material/DataArray";
import { ContactPage } from '@mui/icons-material';
import { getCurrentUser } from '../../../services/auth.services';
import ListItemNavLink from './ListItemNavLink';
import config from '../../../config';

export default function QCANavbar({
  open,
  handleClose,
  drawerWidth = config.uiSettings.drawerWidth,
}) {
  const { appUser, customerAdmin, superUser } = config.userTypes;

  const navItems = [
    {
      key: 1,
      to: config.routes.qca.guide,
      icon: <MenuBookTwoToneIcon />,
      label: 'Documentation',
      userAccess: [appUser, customerAdmin, superUser],
    },
    {
      key: 2,
      to: config.routes.qca.askQuestion,
      icon: <QuestionAnswerTwoToneIcon />,
      label: 'Ask Question',
      userAccess: [appUser, customerAdmin, superUser],
    },
    {
      key: 3,
      to: config.routes.qca.dashboard,
      icon: <DashboardTwoToneIcon />,
      label: 'Dashboard',
      userAccess: [appUser, customerAdmin, superUser],
    },

    {
      key: 4,
      to: config.routes.qca.feedback,
      icon: <MessageTwoToneIcon />,
      label: 'Feedback',
      userAccess: [appUser, customerAdmin, superUser],
    },
    // {
    //   key: 5,
    //   to: config.routes.qca.configurationRequest,
    //   icon: <DatasetIcon />,
    //   label: "Configuration Request",
    //   userAccess: [appUser, customerAdmin],
    // },
    {
      key: 6,
      to: config.routes.qca.contactUs,
      icon: <ContactPage />,
      label: 'Contact Us',
      userAccess: [appUser, customerAdmin, superUser],
    },
  ];

  const user = getCurrentUser();

  return (
    <div>
      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: drawerWidth },
        }}
      >
        <Box
          sx={{ marginTop: `${config.uiSettings.appbarHeight}px` }}
          width="100%"
        >
          <List>
            {navItems.map((item) => {
              if (item?.userAccess?.includes(user?.typeId)) {
                return (
                  <ListItemNavLink
                    key={item.key}
                    to={item.to}
                    primary={item.label}
                    icon={item.icon}
                  />
                );
              }
              return null;
            })}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
