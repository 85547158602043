/* eslint-disable import/no-cycle */
import axios from 'axios';
import { store } from '../redux/store';
import config from './index';
import actionTypes from '../redux/actions';
import { refreshTokens } from '../services/auth.services';

const privateInstance = axios.create();
const publicInstance = axios.create();
privateInstance.defaults.timeout =
  config.appSettings.requestTimeoutDuration * 1000;

// intercepting the request for each api call
privateInstance.interceptors.request.use(
  (conf) => {
    const state = store.getState();
    const updatedConf = conf;
    const { accessToken } = state.auth;
    if (accessToken) {
      updatedConf.headers.Authorization = `Bearer ${accessToken}`;
    }
    return updatedConf;
  },
  (error) => error,
);
// intercepting the response for each api call
// and for 401 error need to refresh tokens
function resetAuthState() {
  store.dispatch({
    type: actionTypes.auth.USER_LOGOUT,
    payload: '',
  });
}

privateInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    new Promise((resolve, reject) => {
      const refreshUrl = `${config.authApiUrl}/auth/refresh/token`;
      const originalRequest = error.config;

      // Check if the response status is 401 (Unauthorized)
      if (error.response && error.response.status === 401) {
        const state = store.getState();
        const { accessToken } = state.auth;
        const { refreshToken } = state.auth;

        // Check if the original request URL is the refresh URL
        if (originalRequest.url === refreshUrl) {
          resetAuthState();
          const currentUrl = window.location.href;
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set('m', 'Session expired please login again.');
          const newUrl = `${currentUrl.split('?')[0]}?${urlParams.toString()}`;
          window.history.pushState(null, null, newUrl);
          reject(error);
        }

        refreshTokens({
          accessToken,
          refreshToken,
        })
          .then((res) => {
            const newAccessToken = res?.data?.data?.accessToken;
            const newRefreshToken = res?.data?.data?.refreshToken;

            store.dispatch({
              type: actionTypes.auth.SET_ACCESS_TOKEN,
              payload: newAccessToken,
            });

            store.dispatch({
              type: actionTypes.auth.SET_REFRESH_TOKEN,
              payload: newRefreshToken,
            });
            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            // Retry the original request with the new token
            return axios(originalRequest);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              resetAuthState();
            }
            return reject(err);
          });
      } else {
        reject(error);
      }
      // For non-401 errors, simply reject the error
    }),
);

const ops = {
  get: privateInstance.get,
  post: privateInstance.post,
  put: privateInstance.put,
  delete: privateInstance.delete,
  publicGet: publicInstance.get,
  publicPost: publicInstance.post,
  publicPut: publicInstance.put,
};
export default ops;
