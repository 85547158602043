import React from 'react';
import { Fade, Box, useTheme } from '@mui/material';
import loaderDark from '../../res/images/q-logo-dark.png';
import loaderLight from '../../res/images/q-logo-light.png';
import config from '../../config';

export default function PageLoader({ loading, size = 30 }) {
  const theme = useTheme();
  const logo =
    theme.palette.mode === config.appSettings.theme.dark
      ? loaderDark
      : loaderLight;
  if (loading) {
    return (
      <Fade in={loading}>
        <Box
          p={2}
          width="100%"
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img title="loader" src={logo} alt="loader" height={size} />
        </Box>
      </Fade>
    );
  }
  return null;
}
