/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import styled from '@emotion/styled';
import {
  Typography,
  Box,
  Container,
  Paper,
  Drawer,
  ListItemIcon,
  Collapse,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';

import MenuBookSharpIcon from '@mui/icons-material/MenuBookSharp';

import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { useLocation } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { useTheme } from '@emotion/react';
import AppbarWithNavigation from '../../../components/core/appBar';

const drawerWidth = 320;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isMobile }) => ({
    flexGrow: 1,
    width: '100%',
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    marginLeft: 0,

    ...(open && {
      transition: theme.transitions.create('margin', {
        // width: "100%",
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
      width: isMobile ? '100%' : '100%' - drawerWidth,
    }),
  }),
);

export default function QCAGuide() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [open, setOpen] = useState(true);
  const location = useLocation();

  const [selectedParentNav, setSelectedParentNav] =
    React.useState('quickstartguide');
  const [selectedChildNav, setSelectedChildNav] = React.useState('general');

  const quickStartUI = (
    // <Paper>
    <Box>
      <Box>
        <Typography variant="h4" gutterBottom color="primary">
          Quick Start Guide
        </Typography>
        <Typography variant="body1">
          Before you start using Quarrio, there are a handful of tips that will
          make getting started easier and faster.
          <br /> Quarrio allows non-technical users to instantaneously retrieve
          information from systems and databases, without technical knowledge or
          how the data is structured. They receive immediate answers with
          automatically generated visualizations and can ask follow-up
          questions.
          <br /> With Quarrio users can get any information from their systems
          with 100% accuracy simply by speaking in normal business language.
          <br />
          Quarrio answers any question as long as the question is about
          onboarded databases, the question is explicit, the user has access
          rights to the data, and the data has been onboarded in Quarrio.To know
          about your onboarded concepts, you can ask question like:
          <br />
        </Typography>
        <ul>
          <li>What tables can I query </li>
          <li>What can I ask </li>
        </ul>
        <Typography>
          Quarrio is powerful when you follow these tips, especially Tip #1 and
          #2.
        </Typography>
      </Box>
      <br />
      <Box id="short-question">
        <Typography variant="h5" gutterBottom color="primary">
          TIP #1 – Keep questions short.
        </Typography>
        As a rule of thumb - the shorter the question the easier it is for
        Quarrio to understand what you mean <br />
        Examples:
        <ul>
          <li>List sales for 2022 </li>
          <li>Which month had the most sales in 2021 </li>
          <li>What is our opportunity win rate </li>
        </ul>
      </Box>
      <br />
      <Box id="follow-on-questions">
        <Typography variant="h5" gutterBottom color="primary">
          TIP #2a – Break complex questions into a string of questions -
          Follow-on Questions
        </Typography>
        When using follow-on questions, keep a simple rule in mind, Follow-on
        questions use the answer from the previous question as the basis for the
        next question. <br />
        For example:
        <ul>
          <li>What were our sales in 2021 </li>
          <li>List those for each industry </li>
          <li>Which of them are above 1 million </li>
        </ul>
      </Box>
      <br />
      <Box id="compound-questions">
        <Typography variant="h5" gutterBottom color="primary">
          TIP #2b – Break complex questions into a string of questions -
          Compound Questions
        </Typography>
        Avoid using compound questions, instead of asking <br />
        <Box textAlign="center" mt={1}>
          <i>
            Which customers had total sales above 100k in 2021 from New York
            City
          </i>
        </Box>
        you can break the question into 2 and use a follow-on question, for
        example the above question can be asked as
        <ul>
          <li>
            <b>Question 1:</b> Which customer had total sales above 100k in 2021
          </li>
          <li>
            <b>Question 2:</b> Which of THOSE are from New York City
          </li>
        </ul>
      </Box>
      <br />
      <Box id="complex-sentence-structures">
        <Typography variant="h5" gutterBottom color="primary">
          TIP #3 – Complex Sentence Structures:
        </Typography>
        Quarrio can process complex questions like
        <ul>
          <li>
            List accounts that have sales in 2023 but had no sales in 2022
          </li>
          <li>
            List accounts by total sales above 900k and below 1 million from
            April 2022 to September 2023
          </li>
        </ul>
      </Box>
      <br />
      <Box id="general-tips">
        <Typography variant="h5" gutterBottom color="primary">
          General Tips
        </Typography>
        <ol style={{ listStyleType: 'lower-alpha' }}>
          <li>
            <b>Avoid ambiguity </b>
            <ul style={{ listStyleType: 'circle' }}>
              <li>
                Example: “What is our top sale, Specify what does top refers to
                <ul style={{ listStyleType: 'lower-roman' }}>
                  <li>by highest revenue?</li>
                  <li>by size?</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <b>Be specific with your questions, </b> if you don’t specify a date
            range, Quarrio assumes “for all time”
            <ul style={{ listStyleType: 'circle' }}>
              <li>Example: “List sales”</li>
            </ul>
          </li>
          <li>
            Quarrio supports common numerical contractions, i.e. :100 K, 10 K, 1
            M etc.
          </li>
          <li>
            Certain terms will generate certain answers
            <ul style={{ listStyleType: 'circle' }}>
              <li>“How many” generates a number </li>
              <li>“Which” and “what” generate a table </li>
              <li>“When” generates a date </li>
            </ul>
          </li>
          <li>
            Save and repeat questions - Rather than having to type them all the
            time Simply click the pin icon next to your question and Quarrio
            creates a dashboard for you
          </li>
          <li>To re-ask a question, just click on it in your history.</li>
          <li>
            For <b>unanswered questions,</b> simply click the feedback icon and
            fill out the dialogue box presented; quarrio will get it working for
            you
          </li>
          <li>
            <b>Watch your spelling,</b> Quarrio spell checker is coming in
            Q1-23.
          </li>
          <li>
            Quarrio can be accessed from browsers on desktops, laptops, tablets,
            and mobile devices.
          </li>
          <li>
            Use exact values from the dataset when asking about filters. E.g.,
            &ldquo;sales region 18 and&rdquo; and NOT &ldquo;sales region 18 and
            99&rdquo;.
          </li>
          <li>
            All questions are good questions. Ask what you want and please click
            the feedback button.
          </li>
        </ol>
      </Box>
      <br />
      <Box id="grouping-sorting-selecting">
        <Typography variant="h5" gutterBottom>
          Quarrio AI Engine’s Features
        </Typography>
        <Typography variant="h6" gutterBottom color="primary">
          Grouping, Sorting and Selecting
        </Typography>
        <Typography gutterBottom>
          To see just the information they seek, in the format or order they
          want, users can group, sort or select a portion of the data within the
          question.
        </Typography>
        <ul>
          <li>
            <b>Grouping</b>
            <ul>
              <li>
                Instruction to group results together using a criterion
                <ul>
                  <li>
                    “List accounts by <b>industry</b>”
                  </li>
                  <li>
                    “List number of accounts <b>per industry</b>”
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <b>Sorting</b>
            <ul>
              <li>
                Instruction to order the results across a criterion
                <ul>
                  <li>
                    “List accounts <b>ordered by sales</b>”
                  </li>
                  <li>
                    “List accounts <b>by total sales</b>”
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <b>Selecting</b>
            <ul>
              <li>
                Instruction to limit the results to specific values of a
                criterion
                <ul>
                  <li>&ldquo;List accounts created in 2023&rdquo;</li>
                  <li>
                    “List accounts <b>created in 2023</b> and
                    <b>&nbsp;from technology industry</b>”
                  </li>
                  <li>
                    “List accounts that are <b>from USA</b> and
                    <b>&nbsp; created in 2023</b>”
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <Typography
          variant="h6"
          gutterBottom
          color="primary"
          id="negation-and-conjunctions"
        >
          Negation and Conjunctions
        </Typography>
        <Typography gutterBottom>
          With negation (not, ...) and conjunctions (and, or, …), users can
          create more complex questions to get the subset of information they
          seek.
        </Typography>
        <ul>
          <li>
            <b>Negation</b>
            <ul>
              <li>
                List accounts where <b>the billing state is not New York</b>
              </li>
              <li>
                List accounts
                <b>&nbsp; not created before 2023</b>
              </li>
              <li>
                List accounts with
                <b>&nbsp; sales not below 1 million</b>
              </li>
            </ul>
          </li>
          <li>
            <b>Conjunction</b>“and” and “or” can be used separately but not
            directly together in one question
            <ul>
              <li>
                List accounts where <b>industry</b> is education or agriculture
              </li>
              <li>
                List accounts
                <b>&nbsp; created in </b> 2022 <b>and</b> 2023
              </li>
              <li>
                List opportunities that were <b>created before</b> 2023&nbsp;
                <b>and closed after&nbsp; </b>2021
              </li>
            </ul>
          </li>
        </ul>
        <Typography
          variant="h6"
          gutterBottom
          color="primary"
          id="follow-on-question1"
        >
          Follow-on Question to Drill Down on Information
        </Typography>
        <Typography gutterBottom>
          Using follow-on questions to combine “and” / “or”
        </Typography>
        <ul style={{ listStyle: 'none' }}>
          <li>
            <ul>
              <li>List sales</li>
              <li>
                Which <b>of them</b> were created in 2022
              </li>

              <li>
                List <b>their</b> industry
              </li>
              <li>
                List <b>their</b> contacts
              </li>
            </ul>
          </li>
        </ul>

        <Typography
          variant="h6"
          gutterBottom
          color="primary"
          id="calculations-and-kpis"
        >
          Calculations and KPIs
        </Typography>
        <Typography gutterBottom>
          Quarrio applies calculations and KPIs by understanding the underlying
          mathematical concepts and appropriately applying them depending on the
          specifics and context of the question. <br />
          Calculations can be combined with Grouping, Sorting and Selecting.
          <br />
          Examples
        </Typography>
        <ul>
          <li>
            {/* What is our <b>lead to sale conversion rate</b> for 2022{" "} */}
            what is our <b>average time to close a sales opportunity</b>
          </li>
          <li>
            How many <b>opportunities did we lose</b> in 2022
          </li>
          <li>
            {/* What is our <b>lead to pipeline conversion rate</b> */}
            What is our <b>pipeline to sales ratio</b>
          </li>
        </ul>
      </Box>
    </Box>
    // </Paper>
  );
  const onBoardUI = (
    <Box>
      <Box>
        <Typography variant="h4" gutterBottom color="primary">
          Data Onboarding Request & Process
        </Typography>
        {/* <Typography variant="body1">
          Before you start using Quarrio, there are a handful of tips that will
          make getting started easier and faster.
          <br /> Quarrio allows non-technical users to instantaneously retrieve
          information from systems and databases, without technical knowledge or
          how the data is structured. They receive immediate answers with
          automatically generated visualizations and can ask follow-up
          questions.
          <br /> With Quarrio users can get any information from their systems
          with 100% accuracy simply by speaking in normal business language.
          <br />
          Quarrio answers any question as long as the question is about
          onboarded databases, the question is explicit, the user has access
          rights to the data, and the data has been onboarded in Quarrio.To know
          about your onboarded concepts, you can ask question like:
          <br />
        </Typography>
        <ul>
          <li>What tables can I query </li>
          <li>What can I ask </li>
        </ul>
        <Typography>
          Quarrio is powerful when you follow these tips, especially Tip #1 and
          #2.
        </Typography> */}
      </Box>
      <br />
      <Box id="client-requirements">
        <Typography variant="h5" gutterBottom color="primary">
          Requirements for the Client
        </Typography>

        <ol>
          <li>
            Quarrio needs read-only access to a client’s Salesforce data, in
            order to execute queries.
          </li>
          <li>
            Clients will need to complete the manual onboarding process with
            Quarrio’s Professional Services Team to onboard their own concepts.{' '}
          </li>
          <li>
            Quarrio Professional Services engagements are necessary, since
            client specific concepts and data mappings exercises must be
            performed in order to leverage the full functionality of the AI
            Engine. Every organization is unique and so are their business
            concepts, terminology and data.{' '}
          </li>
        </ol>
      </Box>
      <br />
      <Box id="initial-onboarding-process">
        <Typography variant="h5" gutterBottom color="primary">
          Initial Onboarding Process
        </Typography>

        <ol>
          <li>
            To begin, a client’s administrator will need to install the Quarrio
            Add-on from the Heroku Elements Market Place and follow the
            instructions provided. Or, simply click the button below:{' '}
          </li>
          <li>
            Once the Quarrio Add-on is finished installing, the client will be
            prompted to provide an administrative email address from within the
            app.
          </li>
          <li>
            Once the client’s administrative email address has been submitted,
            Quarrio will send an email to that client, containing the Quarrio
            SalesForce app URL and temporary account credentials. Further
            instructions needed to complete the process will also be included in
            that email.
          </li>
          <li>
            Next, the client can use the credentials provided in their email to
            log into the Quarrio app, from within their <b>SalesForce</b>{' '}
            account. Every new instantiation of Quarrio contains demo (seed)
            data that the customer can use to evaluate the product.
          </li>
          <li>
            It is <b>highly</b> recommended that the client changes their
            password at this point.
          </li>
          <li>
            A client will then be able to evaluate their Quarrio demo and
            consider advancing to a <b>Limited Engagement</b>, for onboarding
            their own organizational data.
          </li>
        </ol>
      </Box>
      <br />
      <Box id="onboarding-client-data">
        <Typography variant="h5" gutterBottom color="primary">
          Onboarding Client Data
        </Typography>
        Any client who wishes to proceed with onboarding their own
        organizational data will need to open a Limited Engagement Request. A
        Limited Engagement Request will include access to a dedicated account
        representative at Quarrio that can answer any questions a client may
        have about onboarding and to guide them during the process.
      </Box>
      <Box mt={2} id="requesting-limited-engagement">
        <Typography variant="h5" gutterBottom color="primary">
          Requesting A Limited Engagement
        </Typography>

        <ol>
          <li>
            Clients can request a Limited Engagement Session from within
            Quarrio’s app, see “Data Onboarding Request” section (link).
          </li>
          <li>
            Once the request is received, Quarrio Support will reach out to the
            client in order to begin the process of onboarding their data. All
            documentation that is needed in order to successfully complete the
            Data Onboarding process will be provided.
          </li>
          <li>
            Clients can onboard their data into Heroku via Heroku Connect or
            Quarrio ETL.
          </li>
          <li>
            <b>Important:</b> As part of the engagement, Quarrio’s onboarding
            team will require a dedicated session with the client’s
            Point-of-Contact who handles roles and permissions security for
            their SalesForce org. This is necessary in order to map user
            permissions so that they are honored the same way in Quarrio as they
            are in a client’s SalesForce org.
          </li>
          <li>
            Clients will need to connect their newly imported Salesforce data to{' '}
            <b>
              Quarrio’s APIs. Quarrio requires read-only access to a client’s
              organizational data in order to execute queries.
            </b>
            <Box mt={2}>
              <Typography gutterBottom>
                The client will need to provide the following credentials from
                their Heroku PostgreSQL database:
              </Typography>
              <ol style={{ listStyle: 'lower-roman' }}>
                <li>Database Hostname</li>
                <li>Database Name</li>
                <li>Database User Name</li>
                <li>Database User Password</li>
              </ol>
            </Box>
          </li>
        </ol>
      </Box>
      <Box mt={2} id="concept-modeling-mapping">
        <Typography variant="h5" gutterBottom color="primary">
          Concept Modeling and Mapping
        </Typography>

        <Typography>
          Once the client’s data is imported into their Heroku PostgreSQL
          database Instance, they will need to have a{' '}
          <b>Concept Modeling and Mapping</b> session with Quarrio’s onboarding
          team in order to complete the process. Steps are listed below:
        </Typography>
        <ol>
          <li>
            In the onboarding process, Quarrio onboards the dataset through a
            concept driven approach. Any entity that you want to onboard will be
            treated as a concept.
          </li>
          <li>
            Quarrio’s onboarding team will engage with a domain expert from the
            client’s organization to acquire the onboarding information below:
            <ol style={{ listStyle: 'lower-roman' }}>
              <li>
                <b>Concepts:</b> Identify concepts that need to be onboarded
                (concept, type, description/definition, language terms, mapped
                fields)
              </li>
              <li>
                <b>Relationships:</b> Business level relationship among concepts
                (relationship concepts, relation type)
              </li>
              <li>
                <b>Concept DB Values:</b> Conditions/Filters can be applied on
                certain type of concepts automatically (for example, date,
                number, and currency type of concepts). Any other concept on
                which the client wishes to apply any filters/conditions, needs
                to be identified. Identify concepts whose values can be asked
                about in questions.
              </li>
            </ol>
          </li>
        </ol>
        <Typography>
          <b> Important:</b> Quarrio fetches database values for fields such as
          (but not limited to) “Account.Industry”, “Opportunity.StageName”, and
          “Account.Name” to enable answers to questions containing such database
          values.
        </Typography>
      </Box>
    </Box>
  );

  const navItems = [
    {
      name: 'quickstartguide',
      label: 'Quick Start Guide',
      icon: <MenuBookSharpIcon />,
      isParentNav: false,
      component: quickStartUI,
      children: [
        {
          name: 'short-question',
          label: 'TIP #1 – Keep questions short.',

          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'follow-on-questions',
          label: 'TIP #2a – Follow-on Questions',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'compound-questions',
          label: 'TIP #2b – Compound Questions',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'complex-sentence-structures',
          label: 'TIP #3 – Complex Sentence Structures:',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'general-tips',
          label: 'General Tips',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'grouping-sorting-selecting',
          label: 'Grouping, Sorting and Selecting',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'negation-and-conjunctions',
          label: 'Negation and Conjunctions',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'follow-on-question1',
          label: 'Follow-on Question to Drill Down on Information',
          isParentNav: false,
          // component: onBoardUI,
        },
        {
          name: 'calculations-and-kpis',
          label: 'Calculations and KPIs',
          isParentNav: false,
          // component: onBoardUI,
        },
      ],
    },
    {
      name: 'onboardingguide',
      label: 'Onboarding Guide',
      icon: <MenuBookSharpIcon />,
      isParentNav: true,
      children: [
        {
          name: 'client-requirements',
          label: 'Client Requirements',
          isParentNav: false,
          component: onBoardUI,
        },
        {
          name: 'initial-onboarding-process',
          label: 'Initial Onboarding Process',
          isParentNav: false,
          component: onBoardUI,
        },
        {
          name: 'onboarding-client-data',
          label: 'Onboarding Client Data',
          isParentNav: false,
          component: onBoardUI,
        },
        {
          name: 'requesting-limited-engagement',
          label: 'Requesting A Limited Engagement',
          isParentNav: false,
          component: onBoardUI,
        },
        {
          name: 'concept-modeling-mapping',
          label: 'Concept Modeling and Mapping',
          isParentNav: false,
          component: onBoardUI,
        },
      ],
    },
  ];

  const navContentUI = {
    quickstartguide: quickStartUI,
    onboardingguide: onBoardUI,
  };

  useEffect(() => {
    const t = location.state?.parentNav;
    if (t) {
      setSelectedParentNav(t);
    }
  }, []);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 50;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    } else {
      setTimeout(() => {
        handleClickScroll(id);
      }, 1000);
    }
  };

  const handleParentNavigation = (parentNavName) => {
    setSelectedParentNav(parentNavName);
    setSelectedChildNav('');
    window.scrollTo(0, 0);
  };

  const handleSubNavigation = (parentNavName, childNav) => {
    setSelectedParentNav(parentNavName);
    setSelectedChildNav(childNav.name);
    // if (selectedChildNav) {
    //   handleClickScroll(childNav.name);
    // } else {
    // setTimeout(() => {
    handleClickScroll(childNav.name);
    // }, 2000);
    // }
  };

  return (
    <div>
      <AppbarWithNavigation />
      {/* <Box height={80}></Box> */}

      <Box px={1}>
        {!open && (
          <Box py={1}>
            <Tooltip placement="right" title="Expand">
              <IconButton onClick={() => setOpen(true)}>
                <ChevronRight />
              </IconButton>
            </Tooltip>
          </Box>
        )}

        <Drawer
          variant="persistent"
          open={open}
          PaperProps={{
            sx: {
              width: drawerWidth,
              mt: 6,
            },
          }}
        >
          {open && isMobile && (
            <Box py={1}>
              <Tooltip placement="right" title="Collapse">
                <IconButton onClick={() => setOpen(false)}>
                  <ChevronRight />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {navItems.map((e, i) => (
              <Fragment key={i}>
                <ListItemButton
                  onClick={() => handleParentNavigation(e.name)}
                  selected={selectedParentNav === e.name}
                >
                  <ListItemIcon>{e.icon}</ListItemIcon>
                  <ListItemText primary={e.label} />
                </ListItemButton>
                <Collapse
                  in={selectedParentNav === e.name}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {e?.children?.map((c, i) => (
                      <ListItemButton
                        key={i}
                        selected={selectedChildNav === c.name}
                        sx={{ pl: 4 }}
                        onClick={() => handleSubNavigation(e.name, c)}
                      >
                        <ListItemText secondary={c.label} />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ))}
          </List>
        </Drawer>
      </Box>
      <Main open={open} isMobile={isMobile}>
        <Paper>
          <Container>
            <Box p={5}>
              <Box>{navContentUI[selectedParentNav]}</Box>
            </Box>
          </Container>
        </Paper>
      </Main>
    </div>
  );
}
