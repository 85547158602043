/* eslint-disable react/jsx-filename-extension */
import React, { useMemo } from 'react';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { store, persistor } from './redux/store';
import AppRouter from './AppRouter';
import customtheme from './styles/theme';
import config from './config';
import ErrorBoundary from './components/core/ErrorBoundary';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AppTheme />
      </PersistGate>
    </Provider>
  );
}

export default App;

function AppTheme() {
  const currentTheme = useSelector((s) => s.settings.theme);
  const theme = useMemo(() => {
    if (currentTheme === config.appSettings.theme.dark) {
      return createTheme(customtheme.darkTheme);
    }
    return createTheme(customtheme.lightTheme);
  }, [currentTheme]);

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRouter />
      </ThemeProvider>
    </ErrorBoundary>
  );
}
