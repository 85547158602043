import axios from '../config/axios';
import config from '../config';

export const getUsersByOrg = (orgId) =>
  axios.get(`${config.authApiUrl}/user/orgId/${orgId}`);

export const getUserById = (userId) =>
  axios.get(`${config.authApiUrl}/user/search/${userId}`);

export const getUserByType = (typeId) =>
  axios.get(`${config.authApiUrl}/user/type/${typeId}/`);

export const getUserFeedbackQuestions = (userId) =>
  axios.get(`${config.authApiUrl}/feedback/user-feedback/${userId}`);

export const sendEmail = (data) =>
  axios.post(`${config.authApiUrl}/sendEmail/`, data);

export const contactUs = (data) =>
  axios.post(`${config.authApiUrl}/contact-us/`, data);

export const getUserDbSchemas = () =>
  axios.get(`${config.authApiUrl}/user/db-schemas`);

export const updateUserDbSchemas = (data) =>
  axios.put(`${config.authApiUrl}/user/update-user-schemas`, data);
