const authActionTypes = {
  SET_USER: 'SET_USER',
  UPDATE_USER: 'UPDATE_USER',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_AUTH: 'SET_AUTH',
  USER_LOGOUT: 'USER_LOGOUT',
};

export default authActionTypes;
