import axios from '../config/axios';
import config from '../config';

export const getSettingsByUserId = (userId, source = 'default') =>
  axios.get(`${config.authApiUrl}/setting/get/userId/${userId}/${source}`);

export const addUserSettings = (data) =>
  axios.post(`${config.authApiUrl}/setting/`, data);

export const updateUserSettings = (data) =>
  axios.put(`${config.authApiUrl}/setting/`, data);

export const clearHistory = (data) =>
  axios.put(`${config.authApiUrl}/setting/clear-history`, data);
