/* eslint-disable no-underscore-dangle */
import { createStore } from 'redux';
import { persistStore } from 'redux-persist'; // allows browser to cache our store depends on certainer cofiguration
import rootReducer from './index';

const initialState = {};

export const store = createStore(
  rootReducer,
  initialState,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
export const persistor = persistStore(store);
