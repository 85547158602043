import { Snackbar, Stack, useMediaQuery } from '@mui/material';
import React from 'react';
// import config from "../../config";
// import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@emotion/react';

const Alert1 = React.forwardRef((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

export default function CustomAlert({ message, severity = 'error', onClose }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  const vertical = 'top';
  const horizontal = 'center';

  if (message) {
    return (
      <Stack spacing={2} sx={{ width: '100%' }}>
        <Snackbar
          sx={!isMobile ? { minWidth: '26%' } : {}}
          anchorOrigin={{ vertical, horizontal }}
          open={message !== ''}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert1
            onClose={handleClose}
            severity={severity}
            sx={{ width: '100%', minWidth: '200px' }}
          >
            {message}
          </Alert1>
        </Snackbar>
      </Stack>
    );
  }
  return null;
}
