import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button, MenuItem, Paper, Select, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import {
  getCurrentUser,
  isAppUser,
  isCustomerAdmin,
  isSuperUser,
} from '../../services/auth.services';
import {
  clearHistory,
  updateUserSettings,
} from '../../services/settings.services';
import actionTypes from '../../redux/actions';
import globalStyles from '../../styles/globalStyles';
import { getLastnDayDate } from '../../utils/index';
import ConfirmationDialog from '../../components/common/ConfirmationDialog';
import CircularLoader from '../../components/common/CircularLoader';

const gs = globalStyles;
export default function General({
  title = 'General',
  showTitle = true,
  handleSuccess = () => {},
  handleError = () => {},
}) {
  const settings = useSelector((s) => s.settings);
  const dispatch = useDispatch();
  const user = getCurrentUser();
  const conversationThread = useSelector((s) => s.history.conversationThread);
  const [savingSQL, setSavingSQL] = React.useState(false);
  const [savingTheme, setSavingTheme] = React.useState(false);
  const [savingAnswerRows, setSavingAnswerRows] = React.useState(false);
  const [savingParaphrase, setSavingParaphrase] = React.useState(false);
  const [savingVis, setSavingVis] = React.useState(false);
  const [defaultAnswerRows, setDefaultAnswerRows] = React.useState(
    settings?.defaultAnswerRows || config.uiSettings.defaultAnswerRows,
  );
  const currentTheme = useSelector((s) => s.settings.theme);
  const appTheme = config.appSettings.theme;

  const handleShowSQL = async () => {
    try {
      setSavingSQL(true);
      updateUserSettings({
        userId: user.userId,
        showSqlStatus: !settings.showSqlStatus,
      });
      setSavingSQL(false);
      dispatch({
        type: actionTypes.settings.UPDATE_SETTINGS,
        payload: { showSqlStatus: !settings.showSqlStatus },
      });
    } catch (ex) {
      setSavingSQL(false);
    }
  };

  const handleShowParaphrase = async () => {
    try {
      setSavingParaphrase(true);
      await updateUserSettings({
        userId: user.userId,
        showParaphrase: !settings.showParaphrase,
      });
      setSavingParaphrase(false);
      dispatch({
        type: actionTypes.settings.UPDATE_SETTINGS,
        payload: { showParaphrase: !settings.showParaphrase },
      });
    } catch (ex) {
      setSavingParaphrase(false);
    }
  };
  const handleShowVisualizationOnTop = async () => {
    try {
      setSavingVis(true);
      await updateUserSettings({
        userId: user.userId,
        showVisualizationOnTop: !settings.showVisualizationOnTop,
      });
      setSavingVis(false);
      dispatch({
        type: actionTypes.settings.UPDATE_SETTINGS,
        payload: { showVisualizationOnTop: !settings.showVisualizationOnTop },
      });
    } catch (ex) {
      setSavingVis(false);
    }
  };

  const handleChange = async (event) => {
    try {
      setSavingAnswerRows(true);
      await updateUserSettings({
        userId: user.userId,
        defaultAnswerRows: event.target.value,
      });
      setSavingAnswerRows(false);
      dispatch({
        type: actionTypes.settings.UPDATE_SETTINGS,
        payload: { defaultAnswerRows: event.target.value },
      });
    } catch (ex) {
      setSavingAnswerRows(false);
    }
    setDefaultAnswerRows(event.target.value);
  };

  const handleThemeChange = async (e) => {
    const selectedTheme = e.target.value;

    try {
      setSavingTheme(true);
      await updateUserSettings({
        userId: user.userId,
        theme: selectedTheme,
      });
      setSavingTheme(false);

      dispatch({
        type: actionTypes.settings.SET_THEME,
        payload: selectedTheme,
      });
    } catch (ex) {
      setSavingTheme(false);
    }
  };
  const clearHistoryOptions = [
    {
      label: 'Select Options',
      value: 'select',
    },
    {
      label: 'Pinned',
      value: 'pinned',
    },
    {
      label: 'Conversation',
      value: 'conversation',
    },
  ];
  const [selectedClearHistoryOption, setSelectedClearHistoryOption] =
    React.useState(clearHistoryOptions[0].value);
  const [clearingHistory, setClearingHistory] = React.useState(false);
  const [loadingHistory, setLoadingHistory] = React.useState(false);

  const handleClearQuestionChange = (e) => {
    const selectedOp = e.target.value;
    setSelectedClearHistoryOption(selectedOp);
  };

  // const last3Days=
  const loadConvHistoryOptions = [
    {
      label: 'Select Options',
      value: 'select',
    },
    {
      label: 'Last 3 days',
      value: 3,
    },
    {
      label: 'Last 7 days',
      value: 7,
    },
    {
      label: 'Last 30 days',
      value: 30,
    },
  ];

  const [selectedLoadHistoryOption, setSelectedLoadHistoryOption] =
    React.useState(loadConvHistoryOptions[0].value);

  const handleLoadHistoryChange = (e) => {
    const selectedOp = e.target.value;

    setSelectedLoadHistoryOption(selectedOp);
  };
  const clearConversationThread = () => {
    dispatch({
      type: actionTypes.history.SET_CONVERSATION_THREAD,
      payload: [],
    });
  };
  const onClearHistoryConfirm = () => {
    const data = { userId: user.userId };
    if (selectedClearHistoryOption === 'conversation') {
      data.lastHistoryClearedOn = new Date().toISOString();
      setClearingHistory(true);
      updateUserSettings(data)
        .then((res) => {
          dispatch({
            type: actionTypes.history.SET_CONVERSATION_LIST,
            payload: [],
          });
          dispatch({
            type: actionTypes.history.SET_CONVERSATION_THREAD,
            payload: [],
          });

          dispatch({
            type: actionTypes.settings.UPDATE_SETTINGS,
            payload: {
              lastHistoryClearedOn: res?.data?.data?.lastHistoryClearedOn,
            },
          });

          handleSuccess('Conversation history cleared.');
          setSelectedClearHistoryOption(clearHistoryOptions[0].value);
          setClearingHistory(false);
        })
        .catch(() => {
          setClearingHistory(false);
          setSelectedClearHistoryOption(clearHistoryOptions[0].value);
        });
    } else if (selectedClearHistoryOption === 'pinned') {
      data.lastPinQuestionsClearedOn = new Date().toISOString();
      setClearingHistory(true);
      updateUserSettings(data)
        .then(async (res) => {
          dispatch({
            type: actionTypes.history.SET_PINNED_QUESTION_LIST,
            payload: [],
          });
          dispatch({
            type: actionTypes.settings.UPDATE_SETTINGS,
            payload: {
              lastPinQuestionsClearedOn:
                res?.data?.data?.lastPinQuestionsClearedOn,
            },
          });
          await clearHistory({
            userId: user.userId,
            clearType: 'pinnedQuestions',
            clearDateTime: data.lastPinQuestionsClearedOn,
          });
          handleSuccess('Pinned conversation history cleared.');
          setSelectedClearHistoryOption(clearHistoryOptions[0].value);
          setClearingHistory(false);
        })
        .catch(() => {
          setClearingHistory(false);
          setSelectedClearHistoryOption(clearHistoryOptions[0].value);
        });
    }
  };
  const onLoadHistoryConfirm = () => {
    if (selectedLoadHistoryOption !== 'select') {
      setLoadingHistory(true);
      updateUserSettings({
        userId: user.userId,
        lastHistoryClearedOn: getLastnDayDate(selectedLoadHistoryOption),
      })
        .then((res) => {
          setLoadingHistory(false);

          dispatch({
            type: actionTypes.settings.UPDATE_SETTINGS,
            payload: { lastHistoryClearedOn: res?.data.data },
          });
          handleSuccess(
            `Last ${selectedLoadHistoryOption} days history resorted successfully.`,
          );
          setSelectedLoadHistoryOption(loadConvHistoryOptions[0].value);
        })
        .catch((ex) => {
          setLoadingHistory(false);
          setSelectedLoadHistoryOption(loadConvHistoryOptions[0].value);
          handleError(ex?.response?.data?.message);
        });
    }
  };
  const confirmationDialogClearHistory = (
    <ConfirmationDialog
      open={selectedClearHistoryOption !== 'select'}
      disableButtons={clearingHistory}
      dialogTitle="Are you sure?"
      dialogContent={
        clearingHistory ? (
          <Box>
            <CircularLoader loading size={30} />
          </Box>
        ) : (
          `Are you sure you want to clear ${selectedClearHistoryOption} history`
        )
      }
      cancelButtonText="Cancel"
      confirmBtnText="Confirm"
      onCancel={() =>
        setSelectedClearHistoryOption(clearHistoryOptions[0].value)
      }
      onConfirm={onClearHistoryConfirm}
    />
  );
  const confirmationDialogLoadHistory = (
    <ConfirmationDialog
      open={selectedLoadHistoryOption !== 'select'}
      disableButtons={loadingHistory}
      dialogTitle="Are you sure?"
      dialogContent={
        loadingHistory ? (
          <Box>
            <CircularLoader loading size={30} />
          </Box>
        ) : (
          `Are you sure you want to restore last ${selectedLoadHistoryOption} days history`
        )
      }
      cancelButtonText="Cancel"
      confirmBtnText="Confirm"
      onCancel={() =>
        setSelectedLoadHistoryOption(loadConvHistoryOptions[0].value)
      }
      onConfirm={onLoadHistoryConfirm}
    />
  );

  const applicationSettings = (
    <Box>
      <Box my={2}>
        <Typography component="h1" variant="h6">
          Application Settings
        </Typography>
      </Box>
      <Paper sx={gs.greyContainer}>
        <Box display="flex" mt={0}>
          <Box flexGrow={1} mt={0.5}>
            <Typography variant="subtitle1">Theme</Typography>
          </Box>
          <Box>
            <Select
              labelId="demo-simple-select"
              id="demo-simple-select"
              value={currentTheme}
              size="small"
              onChange={handleThemeChange}
              disabled={savingTheme}
              sx={{ textTransform: 'capitalize' }}
            >
              {Object.values(appTheme).map((e) => (
                <MenuItem
                  value={e}
                  key={e}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {e}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </Paper>
    </Box>
  );

  const conversationSettingsUI = (
    <Box>
      <Box my={2}>
        <Typography component="h1" variant="h6">
          Conversation Settings
        </Typography>
      </Box>
      <Paper sx={gs.greyContainer}>
        <Box display="flex" mt={0}>
          <Box flexGrow={1} mt={0.5}>
            <Typography variant="subtitle1">Show SQL</Typography>
          </Box>
          <Box>
            <Switch
              checked={settings.showSqlStatus}
              disabled={savingSQL}
              onChange={handleShowSQL}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        </Box>
      </Paper>
      <Box mt={1}>
        <Paper sx={gs.greyContainer}>
          <Box display="flex">
            <Box flexGrow={1} mt={0.5}>
              <Typography variant="subtitle1">Show Paraphrase</Typography>
            </Box>
            <Box>
              <Switch
                checked={settings.showParaphrase}
                disabled={savingParaphrase}
                onChange={handleShowParaphrase}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mt={1}>
        <Paper sx={gs.greyContainer}>
          <Box display="flex">
            <Box flexGrow={1} mt={0.5}>
              <Typography variant="subtitle1">
                Show Visualization On Top
              </Typography>
            </Box>
            <Box>
              <Switch
                checked={settings.showVisualizationOnTop}
                disabled={savingVis}
                onChange={handleShowVisualizationOnTop}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mt={1}>
        <Paper sx={gs.greyContainer}>
          <Box display="flex">
            <Box flexGrow={1} mt={0.5}>
              <Typography variant="subtitle1" textOverflow>
                No. of Rows Displayed In Response
              </Typography>
            </Box>
            <Box ml={1}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={defaultAnswerRows}
                size="small"
                onChange={handleChange}
                disabled={savingAnswerRows}
                sx={{ minWidth: 65 }}
              >
                {config.uiSettings.rowsPerPageAnswerBoxOptions.map((e) => (
                  <MenuItem value={e} key={e}>
                    {e}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mt={1}>
        <Paper sx={gs.greyContainer}>
          <Box display="flex">
            <Box flexGrow={1} mt={0.5}>
              <Typography variant="subtitle1" textOverflow>
                Clear History
              </Typography>
            </Box>
            <Box ml={1}>
              <Select
                labelId="clear-history-select-label"
                id="clear-history-select"
                value={selectedClearHistoryOption}
                size="small"
                onChange={handleClearQuestionChange}
                disabled={clearingHistory}
                sx={{ minWidth: 150 }}
              >
                {clearHistoryOptions.map((e) => (
                  <MenuItem value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mt={1}>
        <Paper sx={gs.greyContainer}>
          <Box display="flex">
            <Box flexGrow={1} mt={0.5}>
              <Typography variant="subtitle1" textOverflow>
                Load Conversation History
              </Typography>
            </Box>
            <Box ml={1}>
              <Select
                labelId="load-history-select-label"
                id="load-history-select"
                value={selectedLoadHistoryOption}
                size="small"
                onChange={handleLoadHistoryChange}
                disabled={loadingHistory}
                sx={{ minWidth: 150 }}
              >
                {loadConvHistoryOptions.map((e) => (
                  <MenuItem value={e.value}>{e.label}</MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Box mt={1}>
        <Button
          fullWidth
          variant="contained"
          sx={{ p: 1 }}
          disabled={conversationThread.length === 0}
          onClick={clearConversationThread}
        >
          Clear Conversation Thread
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      {confirmationDialogClearHistory}
      {confirmationDialogLoadHistory}

      <Box
        sx={{
          flexDirection: 'column',
        }}
      >
        <Box noValidate>
          <Paper>
            <Box p={2}>
              {showTitle && (
                <Box textAlign="center">
                  <Typography component="h1" variant="h5">
                    {title}
                  </Typography>
                </Box>
              )}
              {applicationSettings}
              {(isAppUser() || isCustomerAdmin() || isSuperUser()) &&
                conversationSettingsUI}
            </Box>
          </Paper>
        </Box>
      </Box>
    </div>
  );
}
