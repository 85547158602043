const historyActionTypes = {
  SET_CONVERSATION_LIST: 'SET_CONVERSATION_LIST',
  SET_CONVERSATION_THREAD: 'SET_CONVERSATION_THREAD',
  ADD_CONVERSATION: 'ADD_CONVERSATION',
  SET_PINNED_QUESTION_LIST: 'SET_PINNED_QUESTION_LIST',
  ADD_PINNED_QUESTION: 'ADD_PINNED_QUESTION',
  UPDATE_CONVERSATION_FILTER: 'UPDATE_CONVERSATION_FILTER',
  SET_FEEDBACK_QUESTIONS_LIST: 'SET_FEEDBACK_QUESTIONS_LIST',
};

export default historyActionTypes;
