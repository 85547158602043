// import { grey } from '@mui/material/colors';
// import config from '../config';

const globalStyles = {
  filledIconButton: {
    py: 1.1,
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.common.white,
    '&:hover': {
      backgroundColor: (theme) => theme.palette.primary.light,
    },
    minWidth: 42,
  },
  greyContainer: {
    // border: "1px solid red",
    // backgroundColor: (theme) => theme.palette.background.default,
    // theme.palette.mode === 'light'
    //   ? theme.palette.grey[200]
    //   : theme.palette.grey[800],

    // mb: 1,
    // py: 1,
    // px: 1,
    p: 1,
    boxShadow: 'none',
    borderRadius: 1,
  },

  selectedListItem: {
    backgroundColor: (theme) => theme.palette.primary.light,
  },
  fixHeighScroll: {
    borderRadius: 2,
    // backgroundColor: (theme) =>
    //   theme.palette.mode === config.appSettings.theme.light
    //     ? grey[300]
    //     : grey[900],
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 250,
    // maxWidth: "fit-content",
  },
  textarea: {
    backgroundColor: () => 'red',
    // width: "100%",
    resize: 'none',
  },
  tableScroll: {
    '&::-webkit-scrollbar': {
      height: 5,
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: 2,
    },
  },
};

export default globalStyles;
