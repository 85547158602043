import config from '../../config';

let { componentLoadAttempts } = config.uiSettings;

export default function lazyRetry(lazyComponent) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 3000 ms
        setTimeout(() => {
          if (componentLoadAttempts === 1) {
            reject(error);
            return window.location.reload();
          }
          componentLoadAttempts -= 1;
          lazyRetry(lazyComponent).then(resolve, reject);
        }, 3000);
      });
  });
}
