import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import HistoryIcon from "@mui/icons-material/History";

// import MemoryIcon from "@mui/icons-material/Memory";
import GroupIcon from '@mui/icons-material/Group';
// import DatasetIcon from '@mui/icons-material/Dataset';
import QuestionAnswerOutlined from '@mui/icons-material/QuestionAnswerTwoTone';
import { Dashboard } from '@mui/icons-material';
import config from '../../../config';
import ListItemNavLink from './ListItemNavLink';

// import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";
// import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";

// import MessageTwoToneIcon from "@mui/icons-material/MessageTwoTone";

// import { getCurrentUser } from "../../../services/auth.services";

export default function CustomerAdminNavbar({
  open,
  handleClose,
  drawerWidth = config.uiSettings.drawerWidth,
}) {
  // const { appUser, customerAdmin } = config.userTypes;

  const navItems = [
    {
      key: 0,
      to: config.routes.customerAdmin.dashboard,
      icon: <Dashboard />,
      label: 'Dashboard',
    },
    {
      key: 1,
      to: config.routes.customerAdmin.users,
      icon: <GroupIcon />,
      label: 'Users',
    },

    {
      key: 2,
      to: config.routes.customerAdmin.questions,
      icon: <QuestionAnswerOutlined />,
      label: 'Questions',
    },
    // {
    //   key: 3,
    //   to: config.routes.customerAdmin.datasource,
    //   icon: <DatasetIcon />,
    //   label: 'Datasource',
    // },
  ];

  // const user = getCurrentUser();

  return (
    <div>
      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: { width: drawerWidth },
        }}
      >
        <Box
          sx={{ marginTop: `${config.uiSettings.appbarHeight}px` }}
          width="100%"
        >
          <List>
            {navItems.map(
              (item) => (
                // if (item?.userAccess?.includes(user?.typeId)) {
                <ListItemNavLink
                  key={item.key}
                  to={item.to}
                  primary={item.label}
                  icon={item.icon}
                />
              ),
              // }
            )}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
