import CryptoJS from 'crypto-js';
import { formatDistance, subDays } from 'date-fns';
import config from '../config';

export const getDateDuration = (localDate) => {
  let duration = '';
  try {
    duration = formatDistance(localDate, Date.now(), {
      addSuffix: true,
    });
  } catch (ex) {
    duration = 'N/A';
  }
  return duration;
};

export const getLastnDayDate = (days = 3) => subDays(new Date(), days);
export const timeoutMessages = (callback) => {
  const to = setTimeout(() => {
    callback();
    clearTimeout(to);
  }, [config.uiSettings.timeoutMessages]);
};

export const renderString = (str) => str || 'N/A';

export const getFieldName = (tableAndField) => {
  // return tableAndField.includes(".")
  //   ? tableAndField.split(".")[1]
  //   : tableAndField;

  if (tableAndField.includes('.')) {
    const arr = tableAndField.split(' ');
    // const lastE = arr[arr.length - 1];
    const updatedArr = arr.map((e) => {
      if (e.includes('.')) {
        return e.split('.')[1];
      }
      return e;
    });
    // return [...arr.slice(0, arr.length - 1), lastE.split(".")[1]].join(" ");
    return updatedArr.join(' ');
  }
  // else if (tableAndField.includes(".")) {
  //   return tableAndField.split(".")[1];
  // }
  return tableAndField;
};

export function downloadExcelCSV(res, filename, format) {
  const { data } = res;
  const json = JSON.stringify(data);
  const buffer = Buffer.from(JSON.parse(json).data);
  const readUTF8 = buffer.toString('utf8');
  let blob = '';

  if (format === 'excel') {
    blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  } else if (format === 'csv') {
    blob = new Blob([readUTF8], { type: 'application/vnd.ms-excel' });
  } else {
    blob = new Blob([readUTF8]);
  }

  const link = document.createElement('a');
  link.href = blob;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(blob);
  link.remove();
}

export const camelCaseToTitleCase = (text) => {
  const result = text?.replace(/([A-Z])/g, ' $1');
  if (result) {
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return '';
};

export const isCamelCase = (text) => !text.match(/[\s_-]/g);

export const toCamelCase = (text) => {
  const regex = /\s+(\w)?/gi;

  const output = text
    ?.toLowerCase()
    ?.replace(regex, (match, letter) => letter?.toUpperCase());

  return output;
};

export const isNumber = (str) => {
  const pattern = /([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])/;
  return pattern.test(str);
};

export const isSpecialCharacter = (str) => {
  const regex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return regex.test(str);
};

export const convertAberevatedNumber = (number) => {
  const base = parseFloat(number);

  let result;
  if (number.toLowerCase().match(/k/)) {
    result = base * 1000;
  } else if (number.toLowerCase().match(/m/)) {
    result = base * 1000000;
  } else if (number.toLowerCase().match(/b/)) {
    result = base * 1000000000;
  }
  return result;
};

export const encrypt = (val) => {
  const secretkey = '/8VNIvQDO//8xcUFgVPDGA=='; // set random encryption key
  const key = CryptoJS.enc.Utf8.parse(secretkey);
  const iv = CryptoJS.enc.Utf8.parse(secretkey.substring(0, 16));

  let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(val), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  encrypted = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
  return encrypted;
};
