import authActions from './auth/auth.actions';
import historyActions from './history/history.actions';
import settingsActions from './settings/settings.actions';

const actionTypes = {
  auth: authActions,
  history: historyActions,
  settings: settingsActions,
};

export default actionTypes;
