/* eslint-disable default-param-last */
import actions from './history.actions';

const INITIAL_STATE = {
  filter: {
    numberOfDays: 3,
  },
  conversationThread: [],
  conversationList: [],
  pinnedQuestionList: [],
  feedbackQuestionList: [],
};

const conversationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_CONVERSATION_LIST:
      return {
        ...state,
        conversationList: action.payload,
      };
    case actions.SET_CONVERSATION_THREAD:
      return {
        ...state,
        conversationThread: action.payload,
      };
    case actions.SET_PINNED_QUESTION_LIST:
      return {
        ...state,
        pinnedQuestionList: action.payload,
      };
    case actions.SET_FEEDBACK_QUESTIONS_LIST:
      return {
        ...state,
        feedbackQuestionList: action.payload,
      };
    case actions.ADD_CONVERSATION:
      return {
        ...state,
        conversationList: [action.payload, ...state.conversationList],
      };
    case actions.ADD_PINNED_QUESTION:
      return {
        ...state,
        pinnedQuestionList: [...state.pinnedQuestionList, action.payload],
      };

    case actions.UPDATE_CONVERSATION_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default conversationReducer;
