import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Box, Grid, Typography } from '@mui/material';
import { ErrorOutlineOutlined } from '@mui/icons-material';
import * as Yup from 'yup';
import config from '../../config/index';
import { getUserById } from '../../services/users.services';
import actionTypes from '../../redux/actions';
import {
  addUserSettings,
  getSettingsByUserId,
} from '../../services/settings.services';
import PageLoader from '../../components/common/PageLoader';

const schema = Yup.object().shape({
  accessToken: Yup.string().required('Access token is required'),
  refreshToken: Yup.string().required('Refresh token is required'),
  source: Yup.string().required('Source is required'),
});
export default function Embed() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [alert, setAlert] = React.useState({
    severity: '',
    message: '',
  });
  const urlString = window.location.href;
  const url = new URL(urlString);
  const accessToken = url.searchParams.get('access_token');
  const refreshToken = url.searchParams.get('refresh_token');
  const source = url.searchParams.get('source');

  const { appUser, customerAdmin } = config.userTypes;

  const handleError = (message) => {
    setAlert({ severity: 'error', message });
  };

  const handleUserSession = async () => {
    setLoading(true);
    const data = { accessToken, refreshToken, source };
    schema
      .validate(data)
      .then(async () => {
        dispatch({
          type: actionTypes.auth.SET_AUTH,
          payload: { accessToken, refreshToken },
        });
        try {
          const payload = jwtDecode(accessToken);
          const res1 = await getUserById(payload.user_id);
          const user = res1.data.data;
          dispatch({
            type: actionTypes.auth.SET_USER,
            payload: user,
          });
          if (
            user &&
            (user.typeId === appUser || user.typeId === customerAdmin)
          ) {
            const res2 = await getSettingsByUserId(user.userId, source);
            let userSettings = res2.data.data;
            if (!userSettings) {
              const res3 = await addUserSettings({
                userId: user.userId,
                theme: 'dark',
              });
              userSettings = res3.data.data;
            }
            dispatch({
              type: actionTypes.history.SET_CONVERSATION_THREAD,
              payload: [],
            });
            dispatch({
              type: actionTypes.settings.UPDATE_SETTINGS,
              payload: {
                ...userSettings,
                app: config.appSettings.app.qca,
              },
            });
            navigate(config.routes.qca.askQuestion);
          } else {
            handleError(
              `${config.routes.error}?m=You are not authorized to access this page.`,
            );
          }
        } catch (ex) {
          handleError(ex?.response?.data?.message || config.errors.default);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        handleError(err?.message);
      });
  };

  useEffect(() => {
    handleUserSession();
  }, []);

  return (
    <div>
      <PageLoader loading={loading} size={40} />
      {alert.message && (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            <Box textAlign="center">
              <Box display="flex">
                <Box mr={2}>
                  <ErrorOutlineOutlined fontSize="large" color="error" />
                </Box>
                <Typography variant="h4" color="error">
                  {alert?.message}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
