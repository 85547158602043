import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';

import config from './config';

import PageLoader from './components/common/PageLoader';
import lazyRetry from './components/core/LazyRetry';
import { getUserById } from './services/users.services';
import actionTypes from './redux/actions';
import QCAGuide from './Pages/qca/guide';
import Embed from './Pages/auth/Embed';
import {
  isAppUser,
  isCustomerAdmin,
  isSuperUser,
} from './services/auth.services';
import ContactUs from './Pages/qca/contactUs';
import { getSettingsByUserId } from './services/settings.services';

const Login = React.lazy(() => lazyRetry(() => import('./Pages/auth/Login')));

const VerifyUser = React.lazy(() =>
  lazyRetry(() => import('./Pages/auth/VerifyUser')),
);
const CreatePassword = React.lazy(() =>
  lazyRetry(() => import('./Pages/auth/CreatePassword')),
);
const RecoverPassword = React.lazy(() =>
  lazyRetry(() => import('./Pages/auth/RecoverPassword')),
);

const ProtectedRoutes = React.lazy(() =>
  lazyRetry(() => import('./routes/ProtectedRoutes')),
);
const AppRoutes = React.lazy(() =>
  lazyRetry(() => import('./routes/AppRoutes')),
);
const CustomerAdminRoutes = React.lazy(() =>
  lazyRetry(() => import('./routes/CustomerAdminRoutes')),
);
const AuthenticatedUserRoute = React.lazy(() =>
  lazyRetry(() => import('./routes/AuthenticatedUserRoutes')),
);
const SuperUserRoutes = React.lazy(() =>
  lazyRetry(() => import('./routes/SuperUserRoutes')),
);

const ConversationList = React.lazy(() =>
  lazyRetry(() => import('./Pages/qca/conversationHistory/ConversationList')),
);
const QcaDashboard = React.lazy(() =>
  lazyRetry(() => import('./Pages/qca/dashboard/index')),
);

const FeedbackQuestion = React.lazy(() =>
  lazyRetry(() => import('./Pages/qca/feedbackQuestion/index')),
);
const Users = React.lazy(() =>
  lazyRetry(() => import('./Pages/customerAdmin/users')),
);
const Dashboard = React.lazy(() =>
  lazyRetry(() => import('./Pages/customerAdmin/dashboard')),
);
// const Datasource = React.lazy(() =>
//   lazyRetry(() => import('./Pages/customerAdmin/datasource')),
// );
const Questions = React.lazy(() =>
  lazyRetry(() => import('./Pages/customerAdmin/questions')),
);

const Settings = React.lazy(() =>
  lazyRetry(() => import('./Pages/settings/index')),
);

const ErrorPage = React.lazy(() =>
  lazyRetry(() => import('./Pages/public/ErrorPage')),
);

export default function AppRouter() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  // const accessToken = useSelector((state) => state.auth.accessToken);
  const [loading, setLoading] = useState(false);

  const publicRoutes = (
    <Route
      path={config.routes.verify}
      element={
        // <ProtectedRoutes>
        <VerifyUser />
        // </ProtectedRoutes>
      }
    />
  );
  const protectedRoutes = (
    <>
      <Route
        path={config.routes.root}
        element={
          <ProtectedRoutes>
            <Login />
          </ProtectedRoutes>
        }
      />
      <Route
        path={config.routes.recoverPassword}
        element={
          <ProtectedRoutes>
            <RecoverPassword />
          </ProtectedRoutes>
        }
      />

      <Route
        path={config.routes.createPassword}
        element={
          <ProtectedRoutes>
            <CreatePassword />
          </ProtectedRoutes>
        }
      />
      <Route
        path={config.routes.embdeding.default}
        element={
          // <ProtectedRoutes>
          <Embed />
          // </ProtectedRoutes>
        }
      />
    </>
  );
  const allUserRoutes = (
    <Route
      path={config.routes.accountSettings}
      element={
        <AuthenticatedUserRoute>
          <Settings />
        </AuthenticatedUserRoute>
      }
    />
  );
  const qcaAppUserRoutes = (
    <>
      <Route
        path={config.routes.qca.askQuestion}
        element={
          <AppRoutes>
            <ConversationList />
          </AppRoutes>
        }
      />
      <Route
        path={config.routes.qca.feedback}
        element={
          <AppRoutes>
            <FeedbackQuestion />
          </AppRoutes>
        }
      />
      <Route
        path={config.routes.qca.dashboard}
        element={
          <AppRoutes>
            <QcaDashboard />
          </AppRoutes>
        }
      />
      <Route
        path={config.routes.qca.guide}
        element={
          <AppRoutes>
            <QCAGuide />
          </AppRoutes>
        }
      />
      {/* <Route
        path={config.routes.qca.configurationRequest}
        element={
          <AppRoutes>
            <ConfigurationRequest />
          </AppRoutes>
        }
      /> */}
      <Route
        path={config.routes.qca.contactUs}
        element={
          <AppRoutes>
            <ContactUs />
          </AppRoutes>
        }
      />
    </>
  );
  const customerAdminRoutes = (
    <>
      <Route
        path={config.routes.qca.askQuestion}
        element={
          <CustomerAdminRoutes>
            <ConversationList />
          </CustomerAdminRoutes>
        }
      />
      <Route
        path={config.routes.qca.feedback}
        element={
          <CustomerAdminRoutes>
            <FeedbackQuestion />
          </CustomerAdminRoutes>
        }
      />
      <Route
        path={config.routes.qca.dashboard}
        element={
          <CustomerAdminRoutes>
            <QcaDashboard />
          </CustomerAdminRoutes>
        }
      />
      <Route
        path={config.routes.qca.guide}
        element={
          <CustomerAdminRoutes>
            <QCAGuide />
          </CustomerAdminRoutes>
        }
      />
      {/* <Route
        path={config.routes.qca.configurationRequest}
        element={
          <CustomerAdminRoutes>
            <ConfigurationRequest />
          </CustomerAdminRoutes>
        }
      /> */}
      <Route
        path={config.routes.customerAdmin.dashboard}
        element={
          <CustomerAdminRoutes>
            <Dashboard />
          </CustomerAdminRoutes>
        }
      />
      <Route
        path={config.routes.customerAdmin.users}
        element={
          <CustomerAdminRoutes>
            <Users />
          </CustomerAdminRoutes>
        }
      />
      {/* <Route
        path={config.routes.customerAdmin.datasource}
        element={
          <CustomerAdminRoutes>
            <Datasource />
          </CustomerAdminRoutes>
        }
      /> */}
      <Route
        path={config.routes.customerAdmin.questions}
        element={
          <CustomerAdminRoutes>
            <Questions />
          </CustomerAdminRoutes>
        }
      />
      <Route
        path={config.routes.qca.contactUs}
        element={
          <CustomerAdminRoutes>
            <ContactUs />
          </CustomerAdminRoutes>
        }
      />
    </>
  );

  const superUserRoutes = (
    <>
      <Route
        path={config.routes.qca.askQuestion}
        element={
          <SuperUserRoutes>
            <ConversationList />
          </SuperUserRoutes>
        }
      />
      <Route
        path={config.routes.qca.feedback}
        element={
          <SuperUserRoutes>
            <FeedbackQuestion />
          </SuperUserRoutes>
        }
      />
      <Route
        path={config.routes.qca.dashboard}
        element={
          <SuperUserRoutes>
            <QcaDashboard />
          </SuperUserRoutes>
        }
      />
      <Route
        path={config.routes.qca.guide}
        element={
          <SuperUserRoutes>
            <QCAGuide />
          </SuperUserRoutes>
        }
      />
      <Route
        path={config.routes.qca.contactUs}
        element={
          <SuperUserRoutes>
            <ContactUs />
          </SuperUserRoutes>
        }
      />
    </>
  );
  const getUserData = async () => {
    try {
      if (user) {
        setLoading(true);

        const res1 = await getUserById(user.userId);
        dispatch({
          type: actionTypes.auth.SET_USER,
          payload: res1.data.data,
        });
        setLoading(false);
        if (window.self === window.top) {
          const res2 = await getSettingsByUserId(user.userId);
          const userSettings = res2.data.data;

          dispatch({
            type: actionTypes.settings.UPDATE_SETTINGS,
            payload: userSettings,
          });
        }
      }
    } catch (ex) {
      setLoading(false);
    }
  };

  useEffect(() => {
    window.addEventListener('load', async () => {
      getUserData();
    });
  }, []);

  if (loading) {
    return <PageLoader loading={loading} size={40} />;
  }

  return (
    <Router>
      <Suspense fallback={<PageLoader loading size={40} />}>
        <Routes>
          {publicRoutes}
          {protectedRoutes}
          {allUserRoutes}
          {isAppUser() && qcaAppUserRoutes}
          {isCustomerAdmin() && customerAdminRoutes}
          {isSuperUser() && superUserRoutes}
          <Route path={config.routes.error} element={<ErrorPage />} />
          <Route path="*" replace element={<Login />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
