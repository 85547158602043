/* eslint-disable react/no-unstable-nested-components */
import React from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography/Typography';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const styles = {
  selectedNavItem: {
    color: (theme) => theme.palette.primary.main,
    height: 40,
  },
  navItem: {
    height: 40,
  },
  navIcon: {
    minWidth: 40,
  },
  selectedNavIcon: {
    minWidth: 40,
    color: (theme) => theme.palette.primary.main,
  },
};

export default function ListItemNavLink(props) {
  const { icon, primary, to } = props;
  const location = useLocation();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
      )),
    [to],
  );
  const isActive = location.pathname === to;

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        sx={isActive ? styles.selectedNavItem : styles.navItem}
      >
        {icon ? (
          <ListItemIcon
            sx={
              isActive
                ? { minWidth: 40, ...styles.selectedNavIcon }
                : { minWidth: 40, ...styles.navIcon }
            }
          >
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText
          primary={<Typography component="h1"> {primary}</Typography>}
        />
      </ListItem>
    </li>
  );
}
