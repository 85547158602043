import React from 'react';
import { CircularProgress, Fade, Box } from '@mui/material';

export default function CircularLoader({
  loading,
  size = 20,
  alignCenter = true,
  color = 'inherit',
}) {
  const centerProps = {
    p: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  const nonCenterProps = {
    mt: 0.5,
    ml: 1,
  };
  if (loading) {
    return (
      <Fade in={loading}>
        <Box sc={alignCenter ? centerProps : nonCenterProps}>
          <CircularProgress size={size} color={color} />
        </Box>
      </Fade>
    );
  }
  return null;
}
