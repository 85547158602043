import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defining what type of storage we want it is actual broswer local storage
import conversationReducer from './history/conversationList.reducer';
import settingReducer from './settings/settings.reducer';
import authReducer from './auth/auth.reducer';
import actionTypes from './actions';

const persistConfig = {
  key: 'root',
  storage,
  whiteList: ['auth', 'history', 'settings', 'admin'],
};

const appReducer = combineReducers({
  auth: authReducer,
  history: conversationReducer,
  settings: settingReducer,
});
const rootReducer = (state, action) => {
  if (action.type === actionTypes.auth.USER_LOGOUT) {
    return appReducer({ settings: { theme: state.settings.theme } }, action);
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
