/* eslint-disable default-param-last */
import actions from './auth.actions';

const INITIAL_STATE = {
  auth: { accessToken: '', refreshToken: '', user: '' },
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_AUTH:
      return {
        ...state,
        accessToken: action?.payload?.accessToken,
        refreshToken: action?.payload?.refreshToken,
        user: action?.payload?.user,
      };
    case actions.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case actions.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case actions.SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    default:
      return state;
  }
};

export default authReducer;
