import { grey } from '@mui/material/colors';

import config from '../config';

// console.log(blueGrey[50]);

const darkPaper = '#1B1D22';
const darkMain = '#7E5DEB';
const darkTheme = {
  palette: {
    mode: config.appSettings.theme.dark,
    primary: {
      // main: red[700],
      main: darkMain,
    },

    secondary: {
      main: grey[600],
      // main: darkPaper,
    },
    background: {
      dark: 'linear-gradient(180deg, #121019 0%, #231A37 100%)',
      default: '#0F1118',
      // default: grey[400],
      paper: darkPaper,
    },
    success: {
      main: darkMain,
    },
    info: {
      main: grey[500],
      // contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 8,
  },
};

const lightMain = '#7E5DEB';
const lightPaper = '#fff';
const lightTheme = {
  palette: {
    mode: config.appSettings.theme.light,
    primary: {
      main: lightMain,
    },

    secondary: {
      main: grey[300],
    },
    background: {
      dark: 'linear-gradient(180deg, #121019 0%, #231A37 100%)',
      default: grey[100],
      paper: lightPaper,
    },
    success: {
      main: lightMain,
    },
    info: {
      main: grey[700],
    },
  },
  shape: {
    borderRadius: 8,
  },

  // components: {
  //   MuiPaper: {
  //     styleOverrides: {
  //       root: {
  //         background: 'linear-gradient(180deg, #121019 0%, #231A37 100%)',
  //       },
  //     },
  //   },
  // },
};

const theme = { lightTheme, darkTheme };
export default theme;
