import React, { Fragment, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import {
  Avatar,
  Card,
  CardContent,
  CssBaseline,
  Divider,
  Drawer,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Settings from '@mui/icons-material/Settings';
import { Close } from '@mui/icons-material';
import actionTypes from '../../../redux/actions';
import {
  getCurrentUser,
  isCustomerAdmin,
  logoutUser,
} from '../../../services/auth.services';
import { updateUserSettings } from '../../../services/settings.services';
import General from '../../../Pages/settings/General';
// import logoDark from '../../../res/images/logo-dark.png';
import logoLight from '../../../res/images/logo-light.png';
import config from '../../../config';
import QCANavbar from './QCANavbar';
import CustomAlert from '../../common/CustomAlert';
import AppSwitcher from './AppSwitcher';
import CustomerAdminNavbar from './CustomerAdminNavbar';

export default function AppbarWithNavigation({
  appbarHeight = config.uiSettings.appbarHeight,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getCurrentUser();
  const settings = useSelector((s) => s.settings);
  const currentTheme = settings.theme;
  const currentApp = settings.app;
  const {
    showAppbar,
    showLogout,
    showAppSelector,
    showAppSetting,
    showAppSideNav,
    showAdminSideNav,
  } = settings?.sourcesettings || {};

  const [open, setOpen] = useState();
  const [anchorEl, setMenuAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const [showSettingsBar, setShowSettingsBar] = React.useState(false);

  const appTheme = config.appSettings.theme;

  const handleThemeChange = () => {
    const selectedTheme =
      currentTheme === appTheme.dark ? appTheme.light : appTheme.dark;

    try {
      updateUserSettings({
        userId: user.userId,
        theme: selectedTheme,
      });
      dispatch({
        type: actionTypes.settings.SET_THEME,
        payload: selectedTheme,
      });
    } catch (ex) {
      // console.log(ex);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutUser();
      dispatch({ type: actionTypes.auth.USER_LOGOUT, payload: '' });
      navigate(config.routes.root, { replace: true });
    } catch (ex) {
      dispatch({ type: actionTypes.auth.SET_USER, payload: '' });
      setTimeout(() => {
        navigate(config.routes.root, { replace: true });
      }, 1000);
    }
  };

  const handleOpenMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // if (user) {
  const { firstName, lastName } = user || {};
  // }

  const getNameInitials = () => {
    let name = 'U';
    if (firstName && firstName.length > 0) {
      name = firstName.charAt(0).toUpperCase();
    }
    if (lastName && lastName.length > 0) {
      name += lastName.charAt(0).toUpperCase();
    }
    return name;
  };

  const getFullName = () => {
    let name = '';
    if (firstName && firstName.length > 0) {
      name += `${firstName.charAt(0).toUpperCase()}${firstName.substring(1)}`;
    }
    if (lastName) {
      name += ` ${lastName.charAt(0).toUpperCase()}${lastName.substring(1)}`;
    }
    return name;
  };

  const toggleSettingsDrawer = () => setShowSettingsBar(!showSettingsBar);
  const [alert, setAlert] = React.useState({
    severity: '',
    message: '',
  });

  const handleSuccess = (message) => {
    setAlert({ severity: 'success', message });
  };

  const handleError = (message) => {
    setAlert({ severity: 'error', message });
  };

  const handleHomeRedirect = () => {
    if (currentApp === config.appSettings.app.qca) {
      navigate(config.routes.qca.askQuestion);
    }
    if (currentApp === config.appSettings.app.customerAdmin) {
      navigate(config.routes.customerAdmin.users);
    }
  };
  const drawerWidth = config.uiSettings.drawerWidth + 100;

  const [showProfile, setShowProfile] = React.useState(false);

  const handleShowProfile = () => {
    setShowProfile(true);
  };
  const handleColseProfile = () => {
    setShowProfile(false);
  };

  const profileDrawer = (
    <Drawer
      anchor="right"
      open={process.env.NODE_ENV === 'development' && showProfile}
      onClose={handleColseProfile}
      PaperProps={{
        sx: { minWidth: drawerWidth },
      }}
    >
      <Card sx={{ marginTop: `${config.uiSettings.appbarHeight}px` }}>
        <CardContent>
          <Box display="flex">
            <Box flexGrow={1} my={1} />
            <IconButton onClick={handleColseProfile}>
              <Close />
            </IconButton>
          </Box>
          <Avatar
            sx={{
              width: 100,
              height: 100,
              margin: '0 auto',
              fontSize: '3rem',
            }}
          >
            {getNameInitials()}
          </Avatar>
          <Typography variant="h6" sx={{ marginTop: 2, textAlign: 'center' }}>
            Name: {`${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Box sx={{ width: 200, height: 100, margin: '0 auto' }}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: 1 }}
            >
              Oganization: {user?.organization?.name}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: 1 }}
            >
              Schema: {user?.userSchema}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginTop: 1 }}
            >
              Instance Url: {user?.organization.sfInstanceUrl}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Drawer>
  );
  const userAppbar = (
    <Box>
      <CssBaseline />
      <AppBar
        position="fixed"
        // color="primary"
        // color="primary.dark"
        sx={{
          zIndex: (t) => t.zIndex.drawer + 1,
          background: (t) => t.palette.background.dark,
        }}
      >
        <Toolbar
          sx={{
            minHeight: {
              md: appbarHeight,
              sm: appbarHeight,
              xs: appbarHeight,
              lg: appbarHeight,
            },
          }}
        >
          <IconButton
            size="small"
            edge="start"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setOpen(!open)}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Box
            mt={0.5}
            onClick={handleHomeRedirect}
            style={{ cursor: 'pointer' }}
          >
            <img
              alt="logo"
              src={
                logoLight
                // theme.palette.mode === config.appSettings.theme.light
                //   ? logoLight
                //   : logoDark
              }
              height={5 + config.uiSettings.appbarHeight / 2}
            />
          </Box>
          <Box component="div" sx={{ flexGrow: 1 }} />

          {isCustomerAdmin() && showAppSelector && <AppSwitcher />}
          {process.env.NODE_ENV === 'development' && (
            <Tooltip title="Theme">
              <IconButton
                sx={{ ml: 1 }}
                onClick={handleThemeChange}
                color="inherit"
              >
                {theme.palette.mode === config.appSettings.theme.dark ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </Tooltip>
          )}
          {showAppSetting && (
            <Tooltip placement="top" title="Settings">
              <IconButton
                className="step-quick-settings"
                onClick={toggleSettingsDrawer}
                color="inherit"
              >
                <Settings />
              </IconButton>
            </Tooltip>
          )}

          <Avatar onClick={handleOpenMenu} sx={{ cursor: 'pointer' }}>
            {getNameInitials()}
          </Avatar>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openMenu}
            onClose={handleCloseMenu}
            onClick={handleCloseMenu}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleShowProfile}>
              <Avatar /> {getFullName()}
            </MenuItem>
            <Divider />

            {showAppSetting && (
              <MenuItem
                onClick={() =>
                  navigate(config.routes.accountSettings, { replace: true })
                }
              >
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
            )}
            {showLogout && (
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <PowerSettingsNewIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      <Box height={appbarHeight} />
    </Box>
  );
  const settingsDrawer = (
    <Drawer
      anchor="right"
      open={showSettingsBar}
      onClose={toggleSettingsDrawer}
      PaperProps={{
        sx: { minWidth: drawerWidth },
      }}
    >
      <Paper sx={{ height: '100%' }}>
        <Box marginTop={6} height={100}>
          <Box display="flex" p={2}>
            <Box flexGrow={1}>
              <Typography component="h1" variant="h5">
                Settings
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={toggleSettingsDrawer}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          <Divider />
          <General
            showTitle={false}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        </Box>
      </Paper>
    </Drawer>
  );
  const sideNavbar = (
    <>
      {showAppSideNav && (
        <QCANavbar
          open={currentApp === config.appSettings.app.qca && open}
          handleClose={() => setOpen(false)}
        />
      )}
      {showAdminSideNav && (
        <CustomerAdminNavbar
          open={currentApp === config.appSettings.app.customerAdmin && open}
          handleClose={() => setOpen(false)}
        />
      )}
    </>
  );

  return showAppbar ? (
    <>
      {userAppbar}
      {profileDrawer}
      {settingsDrawer}
      {sideNavbar}

      <CustomAlert
        severity={alert?.severity}
        message={alert?.message}
        disapearMsg
        onClose={() => setAlert({})}
      />
    </>
  ) : null;
}
