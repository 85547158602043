/* eslint-disable default-param-last */
import actions from './settings.actions';

import config from '../../config/index';

const LIGHT = config.appSettings.theme.light;

const QCA = config.appSettings.app.qca;

const INITIAL_STATE = {
  theme: LIGHT,
  app: QCA,
  showSqlStatus: true,
  showCorrectedQuestion: true,
  showParaphrase: true,
  defaultAnswerRows: config.defaultUserSettings.defaultAnswerRows,
  lastHistoryClearedOn: null,
  lastPinQuestionsClearedOn: null,
  firstTimeLogin: false,
  showVisualizationOnTop: false,
};

const settingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case actions.SET_APP:
      return {
        ...state,
        app: action.payload,
      };
    case actions.UPDATE_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default settingReducer;
