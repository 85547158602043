import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

export default function ConfirmationDialog({
  open = false,
  disableButtons = false,
  dialogTitle = '',
  dialogContent = '',
  cancelButtonText = 'Cancel',
  confirmBtnText = 'Confirm',
  onCancel = () => {},
  onConfirm = () => {},
  showCancelBtn = true,
  showConfirmlBtn = true,
}) {
  //   const [open, setOpen] = React.useState(false);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          <Box width="100%">{dialogContent}</Box>
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          {showCancelBtn && (
            <Button
              onClick={onCancel}
              disabled={disableButtons}
              color="inherit"
            >
              {cancelButtonText}
            </Button>
          )}
          {showConfirmlBtn && (
            <Button onClick={onConfirm} disabled={disableButtons} autoFocus>
              {confirmBtnText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
